import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/PageLayout/PageLayout"
import SEO from "../components/SEO/SEO"
import { MenuItems } from "../components/NavMenu/Menu"
import BlogTemplate from "../components/Blog/BlogTemplate"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        postImage
        title
        date
        description
        tags
      }
      timeToRead
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const BlogPost: React.FC<any> = ({ data }) => {
  const { html, frontmatter, timeToRead, fields } = data.markdownRemark

  const publishDate = new Date(Date.parse(frontmatter.date))

  return (
    <PageLayout selectedMenu={MenuItems.BLOGS}>
      <SEO
        isBlogPost={true}
        title={frontmatter.title}
        description={frontmatter.description}
        slug={fields.slug}
        imageUrl={frontmatter.postImage}
        datePublished={publishDate}
      />
      <BlogTemplate
        html={html}
        frontmatter={frontmatter}
        timeToRead={timeToRead}
        publishDate={publishDate.toDateString()}
        slug={fields.slug}
        siteUrl={data.site.siteMetadata.siteUrl}
      />
    </PageLayout>
  )
}

export default BlogPost
