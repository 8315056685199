import React from "react"
import {
  generateFacebookShareUrl,
  generateLinkedInShareUrl,
  generateTwitterShareUrl,
} from "../../utils/socialUtils"
import "./style.css"

const BlogTemplate: React.FC<{
  html: any
  frontmatter: any
  timeToRead: number
  publishDate: string
  siteUrl: string
  slug: string
}> = ({ html, frontmatter, timeToRead, publishDate, siteUrl, slug }) => {
  return (
    <section id="blogs" className="blogs-page">
      <div className="container">
        {frontmatter.postImage && (
          <div
            className="blog-hero"
            style={{ backgroundImage: `url(${frontmatter.postImage})` }}
          ></div>
        )}
        <header className="blog-header">
          <h1>{frontmatter.title}</h1>
          <p className="mt-3 mb-4">
            <span className="mr-5">{publishDate}</span>
            <span>{timeToRead} min read</span>
          </p>
          <div className="social-links mt-3">
            <a
              href={generateTwitterShareUrl(siteUrl, slug, frontmatter.title)}
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
              title="Share on Twitter"
            >
              <i className="bx bxl-twitter"></i>
            </a>
            <a
              href={generateLinkedInShareUrl(siteUrl, slug, frontmatter.title)}
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
              title="Share on LinkedIn"
            >
              <i className="bx bxl-linkedin"></i>
            </a>

            <a
              href={generateFacebookShareUrl(siteUrl, slug)}
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
              title="Share on Facebook"
            >
              <i className="bx bxl-facebook"></i>
            </a>
          </div>
        </header>
        <section
          className="blog-body"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {frontmatter.tags && (
          <section className="blog-tags">
            {frontmatter.tags.map((tag: string) => (
              <p className="tag" key={tag}>
                {tag}
              </p>
            ))}
          </section>
        )}
      </div>
    </section>
  )
}

export default BlogTemplate
